import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper1">
              <div id="block-menu-primary-links" className="block block-menu">
                <div className="content">
                  <ul className="menu">
                    <li className="leaf first home">
                      <a href="/home" title="Home">
                        <img src="/sites/migraleve.co.uk/files/images/home.png" />
                      </a>
                    </li>
                    <li className="expanded active-trail aboutmigraines">
                      <a
                        href="/about-migraine"
                        title="About Migraines"
                        className="about-migraine"
                      >
                        ABOUT MIGRAINES
                      </a>
                      <ul className="menu">
                        <li className="leaf first migrainesymptoms">
                          <a
                            href="/migraine-symptoms"
                            title="Migraine Types & Symptoms"
                          >
                            Migraine Types & Symptoms
                          </a>
                        </li>
                        <li className="leaf active-trail potentialmigrainecauses">
                          <a
                            href="/migraine-causes"
                            title="Potential Migraine Causes"
                            className="active"
                          >
                            Potential Migraine Causes
                          </a>
                        </li>
                        <li className="leaf last whatcanyoudoaboutit">
                          <a
                            href="/migraine-treatment-and-prevention"
                            title="Treatment and Relief"
                          >
                            Treatment and Relief
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="leaf ourproducts">
                      <a href="/migraine-relief-products" title="Our Products">
                        OUR
                        <br /> PRODUCTS
                      </a>
                    </li>
                    <li className="leaf migrainemanagementtips">
                      <a href="/migraine-tips" title="Migraine Management Tips">
                        MIGRAINE MANAGEMENT TIPS
                      </a>
                    </li>
                    <li className="leaf migrainetriggerdiary">
                      <a
                        href="/migraine-trigger-diary"
                        title="Migraine Trigger Diary"
                      >
                        MIGRAINE TRIGGER DIARY
                      </a>
                    </li>
                    <li className="leaf last wheretobuy">
                      <a href="/where-to-buy" title="Where to buy">
                        WHERE TO BUY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="homeBannerWrapper">
                <div className="headingwrapper">
                  <div className="titlewrapper">
                    <h1>Potential Migraine Causes</h1>
                    <h4 className="subheading">
                      Genetics play a big role in migraine. This means that it is often inherited in families. It is estimated that up to 60% of the reason people get migraine is because of their genes. If you are a sufferer of Migraine, then being aware of your triggers may help you avoid them.
                    </h4>
                  </div>
                  <div className="imagewrapper">
                    <img src="/sites/migraleve.co.uk/files/images/stressed_woman.jpg" />
                  </div>
                </div>
                <div className="innerPageContentWrapper">
                  <div className="innerPageContent">
                    <div id="node-10" className="node">
                      <div className="content clear-block">
                        <div className="contentInnerWrapper">

                          <h2>Triggers</h2>
                          <p>
                            Migraines can be triggered by several factors, both
                            inside and outside the body. These triggers vary by
                            individual.
                          </p>
                          <p>
                            There are several factors that have been identified as
                            a trigger of a migraine. The following are some
                            possible triggers:
                          </p>

                          <div className="trigger-items">
                            <h2>
                              <span className="trigger_num">1</span> Emotional
                            </h2>
                            <div className="row">
                              <div className="col-auto">
                                <ul>
                                  <li>Stress</li>
                                  <li>Anxiety & Depression</li>
                                  <li>Tiredness</li>
                                  <li>Hormones</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="trigger-items">
                            <h2>
                              <span className="trigger_num">2</span> Physical
                            </h2>
                            <div className="row">
                              <div className="col-auto">
                                <ul>
                                  <li>Sudden vigorous exercise</li>
                                  <li>Teeth grinding</li>
                                  <li>Head injury</li>
                                  <li>Poor posture</li>
                                  <li>Tense muscles</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="trigger-items">
                            <h2>
                              <span className="trigger_num">3</span> Dietary
                            </h2>
                            <div className="row">
                              <div className="col-auto">
                                <ul>
                                  <li>
                                    Consuming foods that contain chemicals / additives (Tyramine, Monosodium glutamate, Nitrates, Aspartame)
                                  </li>
                                  <li>Dehydration</li>
                                  <li>Not eating regularly or skipping meals</li>
                                  <li>Consuming too much caffeine</li>
                                  <li>Drugs</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="trigger-items">
                            <h2>
                              <span className="trigger_num">4</span> Environmental
                            </h2>
                            <div className="row">
                              <div className="col-auto">
                                <ul>
                                  <li>Light</li>
                                  <li>
                                    Sound / Noise
                                  </li>
                                  <li>Using a computer for long periods of time</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <h2>Hormones</h2>
                          <p>
                            Migraine is linked with female hormones. This may be why more women than men have migraine.
                          </p>
                          <p>
                            Many women with migraine have their first migraine attack during their teens, often around the time that their periods start. Some women find that their migraine attacks are linked to their menstrual cycle, so they may have attacks around the start of their period.
                          </p>
                          <p>
                            When women go through the menopause, they may find it difficult to manage their migraine attacks as they are more unpredictable.
                          </p>
                        </div>

                        <p>
                          <strong>
                            We know how Migraines can impact on your quality of
                            life. Which is why MIGRALEVE™ products work to treat
                            symptoms of migraine and provide relief. Click
                            <a href="/products" target="_blank">
                              {" "}
                              here
                            </a>
                            to find out which treatment option may be best for
                            you.
                          </strong>
                        </p>
                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                  <div className="innerPagePromo">
                    <div id="block-block-5" className="block block-block">
                      <div className="content">
                        <div className="migrane_tips">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/ma.png"
                            />
                            <div>
                              <h2>tips</h2>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a title="Tips" href="/tips">
                                Find out more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buying_migraleve">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/bm.png"
                            />
                            <div>
                              <h2>
                                buying migraleve<sup>™</sup>
                              </h2>
                              <p>
                                Find your nearest stockist of Migraleve
                                products.
                              </p>
                              <a title="Where to buy" href="/where-to-buy">
                                Where to buy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="trigger_diaryBg">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/td.png"
                            />
                            <div>
                              <h2>trigger diary</h2>
                              <p>
                                Want to find out what triggers your migraines?
                              </p>
                              <a
                                title="Try a trigger diary"
                                href="/trigger-diary"
                              >
                                Try a trigger diary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="info-section">
                  <div className="row">
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/causes.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Potential Migraine Causes
                          </h2>
                          <p>Read more about what can trigger a migraine.</p>
                          <a
                            href="/migraine-causes"
                            className="learnmore btn-light"
                          >
                            Learn More <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/treatments.jpg" />
                        <div className="content">
                          <h2 className="text-normal">Treatment and Relief</h2>
                          <p>
                            Read more about how you can treat and relieve your
                            migraine.
                          </p>
                          <a
                            href="/migraine-treatment-and-prevention"
                            className="learnmore btn-light"
                          >
                            Learn More <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/trigger_diary.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Migraine Trigger Diary
                          </h2>
                          <p>
                            Track your migraines to gain an understanding of
                            your triggers.
                          </p>
                          <a
                            href="/migraine-trigger-diary"
                            className="learnmore btn-light"
                          >
                            Learn More <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="footerContent">
                <div
                  id="block-menu-menu-site-footer-menu"
                  className="block block-menu"
                >
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first privacypolicy">
                        <a href="/privacy-policy" title="Privacy policy">
                          Privacy policy
                        </a>
                      </li>
                      <li className="leaf legalnotice">
                        <a href="/legal-notice" title="Legal notice">
                          Legal notice
                        </a>
                      </li>
                      <li className="leaf contactus">
                        <a href="/contact-us" title="Contact us">
                          Contact us
                        </a>
                      </li>
                      <li className="leaf cookiepolicy">
                        <a href="/cookie-policy" title="Cookie Policy">
                          Cookie Policy
                        </a>
                      </li>
                      <li className="leaf modernslaveryactstatement">
                        <a
                          href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                          target="_blank"
                        >
                          Modern Slavery Act Statement
                        </a>
                      </li>
                      <li className="leaf last cookiesettings">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <p>
                      <strong>
                        Always read the label. Ask your pharmacist for advice.
                      </strong>
                    </p>
                    <p>
                      MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                      codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                      codeine and buclizine. MIGRALEVE<em>™</em> Yellow contains
                      paracetamol and codeine.
                    </p>
                    <p>
                      Can cause addiction. For three days use only. This
                      medicine can make you feel sleepy. Do not drive while
                      taking this medicine until you know how it makes you feel.
                    </p>
                    <p>
                      This site is published by Johnson &amp; Johnson Limited
                      which is solely responsible for its contents. It is
                      intended for a UK audience. MIGRALEVE<em>™</em> is a
                      registered trademark.
                    </p>
                    <p>
                      This site is best viewed with the following web browsers:
                      Internet Explorer version 8, Firefox version 2+, Safari
                      3+, Chrome version 3+.
                    </p>
                    <p>
                      © Johnson &amp; Johnson Limited 2024. This site was last
                      updated on: 15<sup>th </sup>August 2024
                    </p>
                  </div>
                </div>
                <p>
                  <a
                    id="popup-trigger"
                    href="/sites/migraleve.co.uk/files/pop-up-text"
                    rel="lightmodal"
                  >
                    hide this
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
